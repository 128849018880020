<template>
  <div id="app">
    <section class="content" id="el" v-if="hasNews">
      <div class="back" @click="goback()">
        <img src="../../../assets/news/back.png" alt="" />
      </div>
      <img :src="poster" class="figure" :alt="poster" />
      <h1>
        {{ title }}
      </h1>

      <div class="news-info-row">
        <!-- <div class="time"> -->
        <!-- {{timestampFormat(publishedTime)}} -->
        <!-- {{ $global.formatDate(publishedTime, "dd-MM-yyyy hh:mm") }}
        </div> -->
        <div>
          {{ channel }}
        </div>
        <div class="round"></div>
        <div>
          {{ $global.formatDate(publishedTime, "dd-MM-yyyy hh:mm") }}
        </div>
      </div>

      <div class="slice-line"></div>
      <div class="adsItem">
        <ins
          class="adsbygoogle"
          style="display: block"
          data-ad-client="ca-pub-8908317842209223"
          data-ad-slot="4229741585"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div>
      <section class="content-row" v-html="content"></section>
      <!-- <div class="adsItem">
        <ins
          class="adsbygoogle"
          style="display: block"
          data-ad-client="ca-pub-9543177256867205"
          data-ad-slot="4025921634"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div> -->
    </section>

    <section class="no-data-row" v-if="!hasNews && requirDataAlready">
      <img src="@/assets/news/nodata.png" alt="" />
      <div class="no-data">
        Uh-oh. What you were looking for got away from you
      </div>
    </section>

    <div class="loading" v-if="!requirDataAlready">
      <img src="@/assets/news/loading.png" alt="" />
    </div>
  </div>
</template>

<script>
import "@/css/news/landing.scss";

export default {
  name: "landing",
  data() {
    return {
      requirDataAlready: false,
      channel: "",
      poster: "",
      sourceLink: "",
      title: "",
      newDesc: "",
      publishedTime: "",
      content: "",
      hasNews: false,

      newsId: "",
      showToast: false,
      toastTxt:
        "The video link has been copied to the clipboard, you can share with your friends now",
      newsInfo: "",
      // 内容中间 暂时不用
      insertCode: `<div class="adsItem">
      <ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-8908317842209223"
     data-ad-slot="3826479571"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
     </div>`,
      website: 'freshnewNet',
    };
  },
  mounted() {
    this.newsId = this.$route.query.id;
    if (this.newsId) {
      this.getNews();
    }
    // this.$eventrack("show_h5", 'expose', {
    //   extensions: JSON.stringify({
    //     pve_cur: '/h5page/news/details/',
    //   })
    // });
    this.$eventrackFb("show_details_h5", 'expose', {
      website: this.website,
      pve_cur: '/h5page/news/details/',
    });
    setTimeout(() => {
      window.addAds();
    }, 1000)
  },
  methods: {
    removehref() {
      var hrefss = document.getElementsByClassName("content")[0];
      Array.prototype.slice
        .call(hrefss.getElementsByTagName("a"))
        .forEach(function (item) {
          item.href = "javascript:void(0);";
        });
    },
    showPageChage() {
      document
        .getElementsByClassName("pre-btn")[0]
        .classList.remove("fadeOutLeft");
      document.getElementsByClassName("pre-btn")[0].classList.add("fadeInLeft");

      document
        .getElementsByClassName("next-btn")[0]
        .classList.remove("fadeOutRight");
      document
        .getElementsByClassName("next-btn")[0]
        .classList.add("fadeInRight");
    },
    hidePageChage() {
      document
        .getElementsByClassName("pre-btn")[0]
        .classList.remove("fadeInLeft");
      document
        .getElementsByClassName("pre-btn")[0]
        .classList.add("fadeOutLeft");

      document
        .getElementsByClassName("next-btn")[0]
        .classList.remove("fadeInRight");
      document
        .getElementsByClassName("next-btn")[0]
        .classList.add("fadeOutRight");
    },
    getNews(id) {
      let _this = this;
      this.hasNews = false;
      this.toastTxt = "Loading...";
      this.showToast = true;
      this.$api
        .getNews({
          id: id ? id : this.newsId,
        })
        .then((res) => {
          this.requirDataAlready = true;
          if (res.result_code != 200) {
            console.log("获取失败");
            this.toastTxt = "Opps, there aren't more news to show";
            this.showToast = true;
            return;
          }
          this.hasNews = true;

          try {
            this.newsInfo = JSON.parse(res.data.newsInfo);
          } catch (e) {
            console.log(e);
          }

          this.title = res.data.title;
          this.newDesc = res.data.newDesc;
          this.sourceLink = res.data.sourceLink;

          this.channel = res.data.channel;
          this.publishedTime = res.data.publishedTime;

          this.poster = res.data.headImg;
          let content = res.data.newContent;
          let regex = /<\/p><br \/>/g;
          let count = (content.match(regex) || []).length;
          this.count = Math.floor(count / 3);
          let matchCount = 1
          this.content = content.replace(regex, (match) => {
            matchCount++;
            // return matchCount === this.count ? match + this.insertCode : match;
            return match
          });

          // let result = content.replace(regex, (match) => match + this.insertCode)
          this.showToast = false;

          setTimeout(() => {
            this.removehref();
          }, 500);
        });
    },
    goback() {
      this.$router.go(-1)
    },
  },
};
</script>
